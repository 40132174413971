<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {}
};
</script>
<style lang="scss">
@import "./assets/css/var.scss";
body {
  width: 100%;
  overflow-x: hidden;
}
</style>
