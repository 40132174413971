import axios from "axios";
import config from "@config";
import { Message } from "element-ui";

const instance = axios.create({
  // 前端超时时间
  timeout: 1000 * 30,

  // 表示跨域请求时是否需要使用凭证
  // withCredentials: true,

  baseURL: `${config.apiHost}`

  // xsrfCookieName: 'ctoken',
  // xsrfHeaderName: 'x-csrf-token',

  // 默认是 ajax 异步请求
  // headers: {
  //   'X-Requested-With': 'XMLHttpRequest',
  // },
});

// 配置请求拦截器，支持请求参数isCustomProcessing，表示自定义响应处理
instance.interceptors.request.use(req => {
  // 请求参数存在isCustomProcessing时的处理
  if (
    (req.params && req.params.isCustomProcessing) ||
    (req.data && req.data.isCustomProcessing)
  ) {
    // isCustomProcessing参数用完删除，不要传递给后端
    if (req.params && req.params.isCustomProcessing) {
      delete req.params.isCustomProcessing;
    }
    if (req.data && req.data.isCustomProcessing) {
      delete req.data.isCustomProcessing;
    }

    // 把对isCustomProcessing的处理方法加到transformResponse里去
    if (Array.isArray(req.transformResponse)) {
      req.transformResponse = req.transformResponse.concat([
        result => {
          if (result) {
            result.isCustomProcessing = true;
          }
          return result;
        }
      ]);
    }
  }
  return req;
});

// 配置接口响应拦截器
instance.interceptors.response.use(
  response => {
    // 响应结果
    const result = response.data || {};
    console.log(result);
    // 是否使用自定义处理，可在请求参数里设置
    if (result.isCustomProcessing) {
      return result;
    }

    // 是否异常
    let isException = false;

    // 是否采用默认异常处理
    let isDefaultExceptionProcess = true;

    // 取消默认异常处理
    const cancelDefaultExceptionPrecess = () => {
      isDefaultExceptionProcess = false;
    };

    // 根据新老接口一般规则判断是否异常，不符合这个规则的可以在请求的时候添加isCustomProcessing:true参数，自行处理返回结果
    if (result.code !== "0" && result.message !== undefined) {
      isException = true;
    }

    // 滞后执行，可通过调用cancelDefaultExceptionPrecess方法拦截
    setTimeout(() => {
      if (!isDefaultExceptionProcess) return;

      // 新老接口异常判断
      if (isException) {
        Message.error(result.message || result.msgContent);
      }
    }, 0);

    if (isException) {
      // 如果异常，则返回完整的响应信息，并提供撤销默认异常处理的方法，可允许业务端自行处理异常
      /* eslint-disable */
      return Promise.reject({ ...result, cancelDefaultExceptionPrecess });
    } else {
      return result.data;
    }
  },
  error => {
    // 请求配置信息
    const response = error.response || {};
    // const resConfig = response.config || {};

    // 是否采用默认异常处理
    let isDefaultExceptionProcess = true;
    const cancelDefaultExceptionPrecess = () => {
      isDefaultExceptionProcess = false;
    };

    // 滞后执行，可通过调用cancelDefaultExceptionPrecess方法拦截
    setTimeout(() => {
      if (isDefaultExceptionProcess) {
        // 这里默认对错误不做处理，根据业务需求来调整
        // ...
      }
    }, 0);

    return Promise.reject({ ...error, cancelDefaultExceptionPrecess });
  }
);

export default instance;
