import appConfig from "../config";

// CDN方式引入
const { ZM_JSSDK: JSSDK } = window;

function setConfig() {
  JSSDK.setConfig({
    // environment: 'prod',
    environment: process.env.VUE_APP_RUN_ENV,

    // debug模式, 可选值:error / debug。调试阶段，可以在console查看打印信息
    logLevel: "error",

    // histroy模式
    history: true,

    // 默认不上报PV/UV
    autoReport: false,

    // 对XMLHttpRequest异常自动上报
    interceptor: true
  });
}

function setDefaults(params = {}) {
  JSSDK.setDefaults({
    appId: appConfig.appId,
    appVersion: process.env.APPVERSION,
    cSource: "h5",
    ...params
  });
}

// 上报接口异常埋点
function sendEvent(params = {}) {
  setDefaults();
  JSSDK.sendEvent({
    ...params
  });
}
// 上报接口异常埋点
function reportApiException(params = {}) {
  sendEvent({
    // 事件ID，自定义，数字和英文，必填
    eventId: "api_exception",
    // 事件相关参数
    eventParam: params
  });
}

function zmEvent(eventId, params) {
  JSSDK &&
    JSSDK.sendEvent({
      eventId: eventId,
      eventParam: params
    });
}

/**
 *
 *
 * 事件&页面UV 共用埋点（最全，推荐等级：☆☆☆☆☆） 解析传入的参数，自动选择上报渠道
 *
 * @param { Object } eventObj 事件对象
 * @param { Object } params 携带附加参数(可选，附加值)
 */

function reportEventParse(eventObj, attachParams = {}) {
  const {
    eventId = "",
    eventNo = "",
    actKey = "",
    isPage = false,
    params = {}
  } = eventObj;
  let allParams = {
    ...params,
    ...attachParams
  };
  // 公司埋点判定
  if (eventId) {
    // 公司埋点
    zmEvent(eventId, allParams);
  }
  // 闭环数据埋点判定
  if (eventNo && actKey) {
    let _eventID = isPage ? "OPE_XCX_H5_PAGE_GO_EVENTID" : "OPE_XCX_H5_EVENTID";
    zmEvent(_eventID, {
      ...allParams, // 参数可带可不带
      actKey: actKey,
      eventNo: eventNo
    });
  }
  // 百度埋点判定
  const { eventDesc = "", pageName = document.title } = allParams;
  if (eventDesc) {
    // 百度埋点
    baiduEvent(pageName, eventDesc, allParams);
  }
}

/**
 * 百度埋点说明：https://tongji.baidu.com/web/help/article?id=236&type=0
 * _hmt.push(['_trackEvent', category, action, opt_label, opt_value]);
 *
 * @param {*} category 要监控的目标的类型名称，通常是同一组目标的名字，比如”视频”、”音乐”、”软件”、”游戏”等等。该项必选。
 * @param {*} action 用户跟目标交互的行为，如”播放”、”暂停”、”下载”等等。该项必选。
 * @param {*} opt_label 事件的一些额外信息，通常可以是歌曲的名称、软件的名称、链接的名称等等。该项可选。
 * @param {*} opt_value 事件的一些数值信息，比如权重、时长、价格等等，在报表中可以看到其平均值等数据。该项可选。
 */
function baiduEvent(category, action, opt_label = {}, opt_value = "") {
  _hmt &&
    _hmt.push([
      "_trackEvent",
      category,
      action,
      JSON.stringify(opt_label),
      opt_value
    ]);
}

export default {
  setConfig,
  sendEvent,
  reportApiException,
  setDefaults,
  reportEventParse
};
