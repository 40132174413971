import Vue from "vue";
import App from "./App.vue";
// import ElementUI from 'element-ui';
import { checkAppVersion } from "@zm-op/zmop-utils";
import appConfig from "@config";
import ZmopUI from "@zm-op/zmop-ui";
import router from "@router";
import store from "@store";
import http from "@helper/http";
import * as api from "@api";
import * as helper from "@helper";
import filters from "@filters";
import track from "@helper/track";
import "@directives";
import "@assets/css/g.scss";
import "@helper/element.config.js";
// import { MessageBox } from "element-ui";
// 应用版本更新检测，间隔默认一分钟
// process.env.NODE_ENV === "production" &&
//   checkAppVersion({
//     // filePath: `${appConfig.publicPath || ''}/version.json`,
//     publicPath: appConfig.publicPath,
//     messageBox: MessageBox.confirm
//     // during: 60000
//   });

Vue.use(ZmopUI);

// 阻止启动生产消息，可减少应用体积，避免一些运行时开销
Vue.config.productionTip = false;

// 数据接口
Vue.prototype.$httpApi = api;

// http请求方法
Vue.prototype.$http = http;
// 业务相关的工具方法
Vue.prototype.$helper = helper;

// 埋点监控
track.setConfig();
track.setDefaults();
Vue.prototype.$track = track;

// vue过滤取
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
