import http from "@helper/http";
import config from "@config";

export function getAuthInfo(params) {
  return http.get("/api/auth/sys/initInfo", {
    params: {
      ...config.auth,
      ...params
    }
  });
}
