import http from "@helper/http";

export function studentList(params, config) {
  return http.post("/mock/student/list", params, config);
}

export function studentDelete(ids) {
  return http.post("/mock/student/delete", {
    ids
  });
}

export function studentNew(params) {
  return http.post("/mock/student/new", params);
}

export function studentUpdate(params) {
  return http.post("/mock/student/update", params);
}

export function studentDetail(id) {
  return http.get("/mock/student/detail", {
    params: {
      id
    }
  });
}
