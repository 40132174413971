module.exports = {
  // 服务器接口host, 非同域请求时设置，这里只设置个主要的host
  apiHost: {
    // 开发环境
    dev: "",
    // fat
    fat: "",
    // uat
    uat: "",
    // prod
    prod: ""
  }[process.env.VUE_APP_RUN_ENV],

  // 项目部署的子路径，如应用被部署在 https://p-test.zmlearn.com/my-app，则设置为 /my-app
  publicPath: {
    // 开发环境
    development: "/",

    // fat/uat/prod环境
    production: "/"
  }[process.env.NODE_ENV],

  // 发布系统对应的应用ID，埋点使用
  appId: "13263",

  // 主题色，要跟var.scss里的一起修改，默认为掌门红
  theme: {
    colorPrimary: "#ef4c4f"
  }
};
