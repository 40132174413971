import Vue from "vue";
import VueRouter from "vue-router";
import appConfig from "@config";
import routes from "./routes";
Vue.use(VueRouter);

// fix NavigationDuplicated 错误
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const commonRoutes = [
  {
    path: "/404",
    name: "aqyAct",
    component: () => import("@pages/404.vue"),
    meta: {
      title: "掌门1对1"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: appConfig.publicPath,
  routes: [...commonRoutes, ...routes]
});

// 获取权限，路由守护，权限拦截，设置PV埋点
router.beforeEach(async (to, from, next) => {
  const title = to.meta.title;
  document.title = title || "掌门1对1";
  next();
});

export default router;
