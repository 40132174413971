export default [
  {
    path: "/aqyAct/home",
    name: "aqyAct",
    component: () => import("@pages/aqyAct/home"),
    meta: {
      title: "你舍不得的是我吗？ - 掌门1对1"
    }
  }
];
